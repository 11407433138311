<template>
  <Content :search="false" v-if="inventoryItem">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>
          {{
            inventoryItem.name
              ? inventoryItem.name
              : inventoryItem.catalog_item.name
          }}
        </h2>
      </div>
    </template>

    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <InventoryItemDetails :item="inventoryItem" />

            <template v-if="inventoryItem.service_applications">
              <InventoryItemServiceHistory
                :serviceApplications="inventoryItem.service_applications"
                :inventoryItem="inventoryItem"
              />
            </template>
          </ul>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from 'vue'

const InventoryItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/Inventory/InventoryItem/InventoryItemDetails'))
const InventoryItemServiceHistory = defineAsyncComponent(() =>
  import('@/components/OrderHistory/InventoryItemServiceHistory'))

export default {
  name: "InventoryItem",
  components: {
    InventoryItemDetails,
    InventoryItemServiceHistory,
  },
  created() {
    this.$store.dispatch("clearSingleInventoryItem");
    this.$store.dispatch("getSingleInventoryItem", {
      uuid: this.$route.params.itemUUID,
    });
  },
  computed: {
    ...mapGetters({
      inventoryItem: "inventoryItem",
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>